import stringify from "qs/lib/stringify"
export const productSelect2 = function (select, data) {
    select.select2({
        data: data,
        theme: "bootstrap",
        placeholder: "请选择产品",
        language: "zh-CN",
        allowClear: true,
    });
};

export const queryStringify = (params) => stringify(params, { arrayFormat: 'brackets' })